import API from "@/api";

const state = {
    schools: [],
    selected: {},
    total: 0,
    loading: false,
    filter: {
        similar: "",
        city: "",
        limit: 20,
        offset: 0,
    },
}

const mutations = {
    setSchools(state, list) {
        state.schools = list || []
    },
    addSchools(state, list) {
        state.schools.push(...list || [])
    },
    setSchool(state, school) {
        state.schools.find((val, idx) => {
            if (school.id === val.id) {
                state.schools[idx] = school

                return true
            }

            return false
        })
    },
    addSchool(state, item) {
        state.schools.push(item)
    },
    setSelected(state, school) {
        state.selected = school || {}
    },
    setTotal(state, total) {
        state.total = total || 0
    },
    setFilter(state, filter) {
        state.filter[filter.key] = filter.value
    },
    setOffset(state, offset) {
        state.filter.offset = offset || 0
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state.schools = []
        state.selected = {}
        state.total = 0
        state.loading = false
        state.filter = {
            similar: "",
            city: "",
            limit: 20,
            offset: 0,
        }
    }
}

const actions = {
    async fetchSchools({commit, state}) {
        commit("setLoading", true)

        const {data} = await API.schools.list(state.filter)

        commit("setSchools", data.list || [])
        commit("setTotal", data.total || 0)
        commit("setLoading", false)
    },

    async fetchNextSchools({commit, state}) {
        if (state.filter.offset >= state.total) {
            return
        }

        commit("setLoading", true)
        commit("setOffset", state.filter.offset + state.filter.limit)

        const {data} = await API.schools.list(state.filter)

        commit("addSchools", data.list || [])
        commit("setTotal", data.total || 0)
        commit("setLoading", false)
    },

    async filterSchools({commit, state}, keyValue) {
        commit("setFilter", keyValue)
        commit("setOffset", 0)
        commit("setLoading", true)

        API.debounce(() => {
            API.schools.list(state.filter).then((resp) => {
                commit("setSchools", resp.data.list || [])
                commit("setTotal", resp.data.total || 0)
                commit("setLoading", false)
            })
        }, 250)
    },

    async findSchool({commit, state}, schoolID) {
        let school = state.schools.find((val) => {
            return val.id === schoolID ? val : false
        })

        if (!school) {
            const {data} = await API.schools.findByID(schoolID)

            school = data.data
        }

        commit("setSelected", school)

        return school
    },

    async updateSchool({commit}, school) {
        const {data} = await API.schools.update(school)

        commit("setSchool", data.data)
    },

    async createSchool({commit}, school) {
        const {data} = await API.schools.create(school)

        commit("addSchool", data.data)
    },

    async eventUpdateSchool({commit}, school) {
        commit("setSchool", school)
    },

    resetState({commit}) {
        commit("resetState")
    }
}

const getters = {
    schools(state) {
        return state.schools
    },
    selected(state) {
        return state.selected
    },
    filter(state) {
        return state.filter
    },
    form(state) {
        return state.form
    },
    loading(state) {
        return state.loading
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
