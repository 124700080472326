<template>
  <div class="menu-item">
    <i v-if="icon" class="menu-item-icon" :class="icon"/>
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    text: {type: String, required: true},
    icon: {type: String, required: false},
  }
}
</script>

<style lang="scss" scoped>
.menu-item {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  font-size: 20px;
  margin: 15px 0 0 16px;

  &-icon {
    margin-right: 10px;
  }
}

.menu-item:focus,
.menu-item:hover {
  color: rgba(var(--sv-primary), 1);
}
</style>
