import API from "@/api";

const state = {
    books: [],
    selected: {},
    total: 0,
    loading: false,
    filter: {
        similar: "",
        limit: 20,
        offset: 0,
    },
}

const mutations = {
    setBooks(state, list) {
        list.forEach((val, idx, array) => {
            array[idx].count = val.count || 0
        })

        state.books = list || []
    },
    addBooks(state, list) {
        list.forEach((val, idx, array) => {
            array[idx].count = val.count || 0
        })

        state.books.push(...list || [])
    },
    setBook(state, book) {
        state.books.find((val, idx) => {
            if (book.id === val.id) {
                book.count = book.count || 0
                state.books[idx] = book

                return true
            }

            return false
        })
    },
    addBook(state, book) {
        book.count = book.count || 0
        state.books.push(book)
    },
    setSelected(state, book) {
        book.count = book.count || 0
        state.selected = book || {}
    },
    setTotal(state, total) {
        state.total = total || 0
    },
    setFilter(state, filter) {
        state.filter[filter.key] = filter.value
    },
    setOffset(state, offset) {
        state.filter.offset = offset || 0
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state.books = []
        state.selected = {}
        state.total = 0
        state.loading = false
        state.filter = {
            similar: "",
            limit: 20,
            offset: 0,
        }
    }
}

const actions = {
    async fetchBooks({commit, state}) {
        commit("setLoading", true)

        const {data} = await API.books.list(state.filter)

        commit("setBooks", data.list || [])
        commit("setTotal", data.total || 0)
        commit("setLoading", false)
    },

    async fetchNextBooks({commit, state}) {
        if (state.filter.offset >= state.total) {
            return
        }

        commit("setLoading", true)
        commit("setOffset", state.filter.offset + state.filter.limit)

        const {data} = await API.books.list(state.filter)

        commit("setTotal", data.total || 0)
        commit("addBooks", data.list || [])
        commit("setLoading", false)
    },

    async filterBooks({commit, state}, keyValue) {
        commit("setOffset", 0)
        commit("setFilter", keyValue)
        commit("setLoading", true)

        API.debounce(() => {
            API.books.list(state.filter).then((resp) => {
                commit("setBooks", resp.data.list || [])
                commit("setTotal", resp.data.total || 0)
                commit("setLoading", false)
            })
        }, 250)
    },

    async findBook({commit}, bookID) {
        const {data} = await API.books.findByID(bookID)

        commit("setSelected", data.data)

        return data.data
    },

    async updateBook({commit}, book) {
        const {data} = await API.books.update(book)

        commit("setBook", data.data)
    },

    async createBook({commit}, book) {
        const {data} = await API.books.create(book)

        commit("addBook", data.data)
    },

    eventUpdateBook({commit}, book) {
        commit("setBook", book)
    },

    resetState({commit}) {
        commit("resetState")
    }
}

const getters = {
    books(state) {
        return state.books
    },
    selected(state) {
        return state.selected
    },
    filter(state) {
        return state.filter
    },
    form(state) {
        return state.form
    },
    loading(state) {
        return state.loading
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}

