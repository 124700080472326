import store from "@/store"

/** key: event name, value: storage method.*/
const eventTypes = {
    book: "books/eventUpdateBook",
    school: "schools/eventUpdateSchool",
    teacher: "teachers/eventUpdateTeacher",
}

function setNewSubscriber(subscribers, newSubscriber) {
    localStorage.setItem("subscribers", [...subscribers, newSubscriber].sort().join(','))
}

function getSubscribers() {
    let subscribers = localStorage.getItem("subscribers")

    if (!subscribers) {
        return []
    }

    return subscribers.split(",").map(Number)
}

function getNewSubscriber(subscribers) {
    return findLowest(1, subscribers)
}

function findLowest(n, xs) {
    return xs.indexOf(n) < 0 ? n : findLowest(n + 1, xs);
}

const Events = {
    source: undefined,

    initialSubscriberNumber: 0,
    broadcastChannel: undefined,
    broadcastChannelPublisher: undefined,

    connect() {
        try {
            this.initBroadcastListeners()
        } catch (e) {
            console.error("Broadcast channels unsupported!")
            return
        }

        const subscribers = getSubscribers()
        this.initialSubscriberNumber = getNewSubscriber(subscribers)
        setNewSubscriber(subscribers, this.initialSubscriberNumber)

        if (subscribers.length === 0) {
            this.initEventSource()
        }
    },

    initEventSource() {
        console.log("event source init")

        if (this.source) {
            console.error("EventSource already connected")
        }

        let source = new EventSource(`/api/v1/sse`, {
            withCredentials: true,
        })

        source.onerror = function (evt) {
            console.log("EventSource.onerror:", evt);

            if (this.readyState === EventSource.CONNECTING) {
                console.log(`reconnecting: (readyState=${this.readyState})...`);
            }
        };

        // Init event source handlers.
        for (let key in eventTypes) {
            source.addEventListener(key, (event) => {
                const parsed = JSON.parse(event.data)

                store.dispatch(eventTypes[key], parsed).catch((err) => {
                    console.error(`event update ${key}`, err)
                })

                this.broadcastChannel.postMessage({key: key, data: parsed})
            });
        }

        this.source = source
    },

    initBroadcastListeners() {
        this.broadcastChannel = new BroadcastChannel("broadcast-bus")
        this.broadcastChannelPublisher = new BroadcastChannel("publisher-switch")

        this.broadcastChannelPublisher.addEventListener("message", evt => {
            if (evt.data === this.initialSubscriberNumber) {
                this.initEventSource()
            }
        })

        this.broadcastChannel.addEventListener("message", evt => {
            const {key, data} = evt.data

            if (!eventTypes[key]) return

            store.dispatch(eventTypes[key], data).catch((err) => {
                console.error(`event update ${key}`, err)
            })
        })
    },

    close() {
        const subscribers = getSubscribers()
        const newSubscribers = subscribers.filter(number => number !== this.initialSubscriberNumber)
        localStorage.setItem('subscribers', newSubscribers.sort().join(','))

        if (this.source && newSubscribers.length > 0) {
            this.broadcastChannelPublisher.postMessage(newSubscribers[0])
        }

        if (this.source) {
            this.source.close()
            this.source = undefined
        }
    },
}

window.addEventListener('unload', () => {
    Events.close()
})

export default Events
