import {createRouter, createWebHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        redirect: '/schools'
    },
    {
        path: '/schools',
        name: 'Школы',
        component: () => import('../pages/schools/Schools.vue'),
        meta: {
            layout: 'Default',
        }
    },
    {
        path: '/teachers',
        name: 'Учителя',
        component: () => import('../pages/teachers/Teachers.vue'),
        meta: {
            layout: 'Default',
        }
    },
    {
        path: '/books',
        name: 'Пособия',
        component: () => import('../pages/books/Books.vue'),
        meta: {
            layout: 'Default',
        }
    },
    {
        path: '/orders',
        name: 'Заказы',
        component: () => import('../pages/orders/Orders.vue'),
        meta: {
            layout: 'Default',
        }
    },
    {
        path: '/login',
        name: 'Авторизация',
        component: () => import('../pages/auth/Auth.vue'),
        meta: {
            layout: 'Empty',
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
