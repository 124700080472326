import {createStore} from 'vuex';

import authModule from "@/store/modules/auth.module"
import bookModule from "@/store/modules/book.module"
import schoolModule from "@/store/modules/school.module"
import teacherModule from "@/store/modules/teacher.module";
import ordersModule from "@/store/modules/order.module";

export const store = createStore({
    modules: {
        auth: authModule,
        books: bookModule,
        schools: schoolModule,
        teachers: teacherModule,
        orders: ordersModule,
    },
})

export default store
