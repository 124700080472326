<template>
  <header class="header">
    <i class="header-logo"/>

    <!-- Desktop version -->
    <div v-if="navigation" class="header-wrapper">

      <!-- Left aside -->
      <div class="header-wrapper-left-aside">

        <!-- Navigation menu -->
        <template v-for="(value, idx) in navigation" :key="`nav_link_${idx}`">
          <a class="navigation-link"
             :class="{'hovered': activeRoute === value.link }"
             @click="$router.push(value.link)">
            <span>{{ value.name }}</span>
          </a>
        </template>
      </div>

      <!-- Right aside -->
      <div class="header-wrapper-right-aside">
        <span class="header-wrapper-username">{{ login }}</span>
        <span v-if="login"
              @click="logout()"
              class="header-wrapper-logout-icon"/>
      </div>
    </div>

    <!-- Mobile version -->
    <template v-if="login">
      <i v-if="!menuIsActive"
         class="header-menu-icon"
         @click="menuIsActive = true"/>

      <i v-else
         class="header-close-icon sv-icon-close hover-background"
         @click="menuIsActive = false"/>

    </template>

    <!-- Mobile navigation menu -->
    <div v-if="menuIsActive" class="header-menu">
      <template v-for="(value, idx) in navigation" :key="`nav_link_${idx}`">
        <MenuItem
            :text="value.name"
            @click="changeRoute(value.link)"/>
      </template>

      <MenuItem
          style="margin-top: 45px"
          text="Logout"
          @click="logout()"/>
    </div>
  </header>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MenuItem from "@/components/header/menu/MenuItem";

export default {
  name: "TopMenu",
  components: {
    MenuItem
  },
  props: {
    navigation: {type: Array, required: false}
  },
  data() {
    return {
      menuIsActive: false,
    }
  },
  methods: {
    ...mapActions("auth", [
      "signOut",
    ]),

    logout() {
      this.signOut().then(() => {
        this.$router.push("/login");
      })
    },

    changeRoute(route) {
      this.menuIsActive = false
      this.$router.push(route)
    },
  },
  computed: {
    ...mapGetters("auth", [
      "login",
    ]),

    activeRoute() {
      return this.$router.currentRoute.value.path
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  box-sizing: border-box;
  width: 100%;
  top: 0;
  z-index: 4;
  position: fixed;
  padding-left: 32px;
  padding-right: 32px;
  background: var(--sv-theme-layout);
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgb(0 0 0 / 8%) 0 2px 6px 0, rgb(0 0 0 / 2%) 0 0 2px 0;

  &-logo {
    min-width: 50px;
    min-height: 50px;
    width: 50px;
    height: 50px;
    transition: all 200ms ease-out;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../assets/icons/owl.svg);
  }

  &-logo:hover {
    background-image: url(../../assets/icons/owl_hover.svg);
  }

  &-wrapper {
    -webkit-font-smoothing: antialiased;
    display: flex;
    overflow: hidden;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    height: 30px;
    width: 100%;
    margin: auto;

    &-left-aside {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
    }

    &-right-aside {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
    }

    &-username {
      font-size: 18px;
      margin-right: 10px;
    }

    &-logout-icon {
      min-width: 24px;
      min-height: 24px;
      width: 24px;
      height: 24px;
      transition: all 200ms ease-out;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(../../assets/icons/logout.svg);
    }

    &-logout-icon:hover {
      background-image: url(../../assets/icons/logout_hover.svg);
    }
  }

  &-menu {
    position: fixed;
    display: none;
    flex-direction: column;
    top: 70px;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--sv-theme-layout);
    border-top: 1px solid #EBEEF5;
  }

  &-close-icon {
    display: none;
    position: center;
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin: 15px 5px 0 0;

    &:before {
      height: 2px;
    }

    &:after {
      height: 2px;
    }
  }

  &-menu-icon {
    display: none;
    min-width: 30px;
    min-height: 28px;
    width: 30px;
    height: 28px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../assets/icons/menu.svg);
  }
}

.navigation-link {
  position: relative;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin-left: 24px;
  transition: all 200ms ease-out;
}

.navigation-link:hover {
  color: rgba(var(--sv-primary), 1);
}

.hovered {
  color: rgba(var(--sv-primary), 1);
}

@media (max-width: 768px) {
  .header {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 600px) {
  .header-wrapper {
    display: none;
  }

  .header-menu,
  .header-menu-icon,
  .header-close-icon {
    display: block;
  }
}
</style>
