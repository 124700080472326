import axios from 'axios';
import router from '@/router';
import store from '@/store';

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (router.currentRoute.value.path === "/login") {
        return Promise.reject(error);
    }

    if (error?.response?.status === 401) {
        store.commit("auth/setLogin", "")

        router.push('/login').catch((err) => {
            console.error("redirect to login:", err)
        });
    }

    return Promise.reject(error);
});

const API = {
    debounceTimerIDs: {},

    debounce(func, delay) {
        clearTimeout(this.debounceTimerIDs[`${func}`])

        this.debounceTimerIDs[`${func}`] = setTimeout(func, delay)
    },

    auth: {
        signIn({login, password}) {
            const data = {login, password}

            return axios.post("/api/v1/auth/SignIn", data)
        },
        signOut() {
            return axios.post("/api/v1/auth/SignOut", {})
        }
    },

    schools: {
        create({name, city, addr, schedule}) {
            const data = {name, city, addr, schedule}

            return axios.post("/api/v1/schools/Create", data)
        },
        findByID(schoolID) {
            const data = {id: schoolID}

            return axios.post("/api/v1/schools/FindByID", data)
        },
        list({limit, offset, similar, city, withTeachers}) {
            const data = {limit, offset, similar, city, withTeachers}

            return axios.post("/api/v1/schools/List", data)
        },
        listByIDs(ids) {
            const data = {ids}

            return axios.post("/api/v1/schools/ListByIDs", data)
        },
        update({id, name, city, addr, schedule}) {
            const data = {id, name, city, addr, schedule}

            return axios.post("/api/v1/schools/Update", data)
        },
        suggest(name) {
            return axios.post("/api/v1/schools/Suggest", {name: name})
        }
    },

    teachers: {
        create({schoolID, firstName, lastName, middleName, email, phone, comment}) {
            const data = {schoolID, firstName, lastName, middleName, email, phone, comment}

            return axios.post("/api/v1/teachers/Create", data)
        },
        findByID(teacherID) {
            return axios.post("/api/v1/teachers/FindByID", {id: teacherID})
        },
        list({limit, offset, similar, schoolID}) {
            const data = {limit, offset, similar, schoolID}

            return axios.post("/api/v1/teachers/List", data)
        },
        listByIDs(ids) {
            const data = {ids}

            return axios.post("/api/v1/teachers/ListByIDs", data)
        },
        update({id, schoolID, firstName, lastName, middleName, email, phone, comment}) {
            const data = {id, schoolID, firstName, lastName, middleName, email, phone, comment}

            return axios.post("/api/v1/teachers/Update", data)
        },
    },

    books: {
        create({name}) {
            const data = {name}

            return axios.post("/api/v1/books/Create", data)
        },
        findByID(bookID) {
            return axios.post("/api/v1/books/FindByID", {id: bookID})
        },
        list({limit, offset, similar}) {
            const data = {limit, offset, similar}

            return axios.post("/api/v1/books/List", data)
        },
        listByIDs(ids) {
            const data = {ids}

            return axios.post("/api/v1/books/ListByIDs", data)
        },
        update({id, name}) {
            const data = {id, name}

            return axios.post("/api/v1/books/Update", data)
        }
    },

    orders: {
        create({schoolID, teacherID, deliveryDate, comment, books}) {
            const data = {schoolID, teacherID, deliveryDate, comment, books}

            return axios.post("/api/v1/orders/Create", data)
        },
        findByID(orderID) {
            return axios.post("/api/v1/orders/FindByID", {id: orderID})
        },
        list({limit, offset, city, states, schoolID}) {
            const data = {limit, offset, city, states, schoolID}

            return axios.post("/api/v1/orders/List", data)
        },
        update({id, schoolID, teacherID, deliveryDate, comment, books}) {
            const data = {id: id, schoolID, teacherID, deliveryDate, comment, books}

            return axios.post("/api/v1/orders/Update", data)
        },
        updateState({id, state}) {
            const data = {id, state}

            return axios.post("/api/v1/orders/UpdateState", data)
        }
    }
}

export default API
