<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
import {shallowRef, watch} from 'vue';
import {useRoute} from 'vue-router';

import Default from '@/layouts/Default';
import Empty from '@/layouts/Empty';

export default {
  components: {
    Default,
    Empty
  },
  setup() {
    const layout = shallowRef(Empty)
    const route = useRoute()

    watch(
        () => route.meta,
        (meta) => {

          try {
            const component = require(`@/layouts/${meta.layout}.vue`)
            layout.value = component?.default || Empty
          } catch (e) {
            layout.value = Empty
          }
        },
    )

    return {layout}
  },
}
</script>

<style>
#app {
  color: rgb(var(--sv-text));
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
}

html {
  font-variant: tabular-nums;
  line-height: 1.5;
}

body {
  margin: 0;
  font-size: 16px;
  background-color: var(--sv-theme-bg);
}

body, button, input {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

*, :after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
