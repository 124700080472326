import API from "@/api";
import {joinSchools, joinTeachers} from "@/store/helpers";

const state = {
    locale: window.navigator.language,
    orders: [],
    selected: {},
    total: 0,
    loading: false,
    filter: {
        limit: 20,
        offset: 0,
        city: "",
        schoolID: 0,
        states: [],
    },
}

const mutations = {
    setOrders(state, list) {
        state.orders = list || []
    },
    addOrders(state, list) {
        state.orders.push(...list || [])
    },
    setOrder(state, order) {
        state.orders.find((val, idx) => {
            if (order.id === val.id) {
                state.orders[idx] = order

                return true
            }

            return false
        })
    },
    addOrder(state, item) {
        state.orders.push(item)
    },
    setSelected(state, order) {
        state.selected = order || {}
    },
    setTotal(state, total) {
        state.total = total || 0
    },
    setFilter(state, filter) {
        state.filter[filter.key] = filter.value
    },
    setOffset(state, offset) {
        state.filter.offset = offset || 0
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state.locale = window.navigator.language
        state.orders = []
        state.selected = {}
        state.total = 0
        state.loading = false
        state.filter = {
            limit: 20,
            offset: 0,
            city: "",
            schoolID: 0,
            states: [],
        }
    }
}

const actions = {
    async fetchOrders({commit, state}) {
        commit("setLoading", true)

        const {data} = await API.orders.list(state.filter)
        await joinSchools(...data.list || [])
        await joinTeachers(...data.list || [])

        commit("setTotal", data.total || 0)
        commit("setOrders", data.list || [])
        commit("setLoading", false)
    },

    async fetchNextOrders({commit, state}) {
        if (state.filter.offset >= state.total) {
            return
        }

        commit("setLoading", true)
        commit("setOffset", state.filter.offset + state.filter.limit)

        const {data} = await API.orders.list(state.filter)
        await joinTeachers(...data.list || [])
        await joinSchools(...data.list || [])

        commit("addOrders", data.list || [])
        commit("setTotal", data.total || 0)
        commit("setLoading", false)
    },

    async filterOrders({commit, state}, keyValue) {
        commit("setFilter", keyValue)
        commit("setOffset", 0)
        commit("setLoading", true)

        API.debounce(async () => {
            const {data} = await API.orders.list(state.filter)
            await joinSchools(...data.list || [])
            await joinTeachers(...data.list || [])

            commit("setOrders", data.list || [])
            commit("setTotal", data.total || 0)
            commit("setLoading", false)
        }, 250)
    },

    async findOrder({commit}, orderID) {
        const {data} = await API.orders.findByID(orderID)
        await joinSchools(data.data || {})
        await joinTeachers(data.data || {})

        commit("setSelected", data.data)

        return data.data
    },

    async updateOrder({commit}, order) {
        const {data} = await API.orders.update(order)
        await joinSchools(data.data || {})
        await joinTeachers(data.data || {})

        commit("setOrder", data.data)
    },

    async updateOrderState({commit}, order) {
        await API.orders.updateState(order)

        commit("setOrder", order)
    },

    async createOrder({commit}, order) {
        const {data} = await API.orders.create(order)

        await joinSchools(data.data || {})
        await joinTeachers(data.data || {})

        commit("addOrder", data.data)
    },

    resetState({commit}) {
        commit("resetState")
    }
}

const getters = {
    orders(state) {
        return state.orders
    },
    filter(state) {
        return state.filter
    },
    form(state) {
        return state.form
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
