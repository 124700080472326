import API from "@/api";

const state = {
    login: localStorage.getItem("user-login")
}

const mutations = {
    setLogin(state, login) {
        state.login = login
        localStorage.setItem("user-login", login)
    }
}

const actions = {
    async signIn({commit}, credentials) {
        await API.auth.signIn(credentials)

        commit("setLogin", credentials.login)
    },
    async signOut({commit}) {
        await API.auth.signOut()

        commit("setLogin", "")
    }
}

const getters = {
    login(state) {
        return state.login
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
