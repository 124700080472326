import API from "@/api";
import {joinSchools} from "@/store/helpers";

function teacherFIO(data) {
    return `${data.lastName || ''} ${data.firstName || ''} ${data.middleName || ''}`.trim()
}

const state = {
    teachers: [],
    selected: {},
    total: 0,
    loading: true,
    filter: {
        schoolID: 0,
        similar: "",
        limit: 20,
        offset: 0,
    },
}

const mutations = {
    setTeachers(state, list) {
        list.forEach((val, idx, array) => {
            array[idx].fio = teacherFIO(val)
        })

        state.teachers = list || []
    },
    addTeachers(state, list) {
        list.forEach((val, idx, array) => {
            array[idx].fio = teacherFIO(val)
        })

        state.teachers.push(...list || [])
    },
    setTeacher(state, teacher) {
        state.teachers.find((val, idx) => {
            if (teacher.id === val.id) {
                teacher.fio = teacherFIO(teacher)
                state.teachers[idx] = teacher

                return true
            }

            return false
        })
    },
    addTeacher(state, teacher) {
        teacher.fio = teacherFIO(teacher)
        state.teachers.push(teacher)
    },
    setSelected(state, teacher) {
        teacher.fio = teacherFIO(teacher)
        state.selected = teacher || {}
    },
    setTotal(state, total) {
        state.total = total || 0
    },
    setFilter(state, filter) {
        state.filter[filter.key] = filter.value
    },
    setOffset(state, offset) {
        state.filter.offset = offset || 0
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state.teachers = []
        state.selected = {}
        state.total = 0
        state.loading = true
        state.filter = {
            schoolID: 0,
            similar: "",
            limit: 20,
            offset: 0,
        }
    }
}

const actions = {
    async fetchTeachers({commit, state}) {
        commit("setLoading", true)

        const {data} = await API.teachers.list(state.filter)
        await joinSchools(...data.list || [])

        commit("setTeachers", data.list || [])
        commit("setTotal", data.total || 0)
        commit("setLoading", false)
    },

    async fetchNextTeachers({commit, state}) {
        if (state.filter.offset >= state.total) {
            return
        }

        commit("setLoading", true)
        commit("setOffset", state.filter.offset + state.filter.limit)

        const {data} = await API.teachers.list(state.filter)
        await joinSchools(...data.list || [])

        commit("addTeachers", data.list || [])
        commit("setTotal", data.total || 0)
        commit("setLoading", false)
    },

    async filterTeachers({commit, state}, keyValue) {
        commit("setOffset", 0)
        commit("setFilter", keyValue)
        commit("setLoading", true)

        API.debounce(async () => {
            const {data} = await API.teachers.list(state.filter)
            await joinSchools(...data.list || [])

            commit("setTotal", data.total || 0)
            commit("setTeachers", data.list || [])
            commit("setLoading", false)
        }, 250)
    },

    async findTeacher({commit}, teacherID) {
        const {data} = await API.teachers.findByID(teacherID)
        await joinSchools(data.data || [])

        commit("setSelected", data.data)

        return data.data
    },

    async updateTeacher({commit}, teacher) {
        const {data} = await API.teachers.update(teacher)
        await joinSchools(data.data || [])

        commit("setTeacher", data.data)
    },

    async createTeacher({commit}, teacher) {
        const {data} = await API.teachers.create(teacher)
        await joinSchools(data.data || [])

        commit("addTeacher", data.data)
    },

    async eventUpdateTeacher({commit}, teacher) {
        await joinSchools(teacher || [])

        commit("setTeacher", teacher)
    },

    resetState({commit}) {
        commit("resetState")
    }
}

const getters = {
    teachers(state) {
        return state.teachers
    },
    selected(state) {
        return state.selected
    },
    filter(state) {
        return state.filter
    },
    form(state) {
        return state.form
    },
    loading(state) {
        return state.loading
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
