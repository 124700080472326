import API from "@/api";

function schoolShortName(school) {
    let shortName = ''

    school.city ? shortName += `г. ${school.city}, ` : ''
    school.name ? shortName += `${school.name}` : ''

    return shortName
}

function teacherFIO(data) {
    return `${data.lastName || ''} ${data.firstName || ''} ${data.middleName || ''}`.trim()
}


async function joinSchools(...list) {
    if (!list || list.length === 0) {
        return;
    }

    let schoolIDs = []

    list.forEach((val) => {
        schoolIDs.push(val.schoolID)
    })

    const {data} = await API.schools.listByIDs(schoolIDs)

    list.forEach((val) => {
        let school = data.list[val.schoolID]
        school.shortname = schoolShortName(school)

        val.school = school
    })
}

async function joinTeachers(...list) {
    if (!list || list.length === 0) {
        return;
    }

    let teacherIDs = []

    list.forEach((val) => {
        teacherIDs.push(val.teacherID)
    })

    const {data} = await API.teachers.listByIDs(teacherIDs)

    list.forEach((val) => {
        let teacher = data.list[val.teacherID]
        teacher.fio = teacherFIO(teacher)

        val.teacher = teacher
    })
}

export {
    joinSchools,
    joinTeachers
}