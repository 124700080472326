<template>
  <div class="layout">
    <Header/>

    <div class="content">
      <slot/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/Header';

export default {
  name: "Empty",
  components: {
    Header
  },
}
</script>

<style scoped>
.layout {
  height: 100%;
  width: 100%;
}

.content {
  padding-top: 70px;
  display: flex;
  justify-content: space-between;
}
</style>
