<template>
  <div class="layout">
    <Header :navigation="navigation"/>

    <div class="content">
      <slot/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/Header';
import Events from "@/eventsource";

export default {
  name: 'Default',
  components: {
    Header,
  },
  data() {
    return {
      navigation: [
        {
          name: 'Школы',
          link: "/schools",
        },
        {
          name: 'Учителя',
          link: "/teachers"
        },
        {
          name: 'Пособия',
          link: "/books"
        },
        {
          name: 'Заказы',
          link: "/orders"
        }
      ],
      events: Events,
    }
  },
  mounted() {
    this.events.connect()
  },
  unmounted() {
    this.events.close()
  }
}
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
}

.content {
  padding-top: 70px;
  display: flex;
  justify-content: space-between;
  height: 100%;
}
</style>
