import {createApp} from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

import App from './App.vue';
import router from './router';
import store from './store';
import "@/assets/styles/index.scss"

const app = createApp(App)

app.use(router)
app.use(ElementPlus)
app.use(store)
app.use(VueAxios, axios)
app.mount('#app')
